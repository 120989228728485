<template>
  <img
    class="bf"
    :src="require('@/assets/images/bf@3x.jpg')"
    :srcset="
      require('@/assets/images/bf.jpg') +
      ' w400 ' +
      require('@/assets/images/bf@2x.jpg') +
      ' w580 ' +
      require('@/assets/images/bf@3x.jpg') +
      ' w800 '
    " />
  <MenuSection
    v-for="menuSection in store.menu"
    :key="menuSection.icon"
    :icon="menuSection.icon"
    :title="menuSection.title"
    :items="menuSection.items" />
  <ShoppingCart />
</template>

<script>
import ShoppingCart from '@/components/ShoppingCart'
import MenuSection from '@/components/MenuSection'
import { store } from '@/data/menu'
export default {
  name: 'MenuView',
  components: { ShoppingCart, MenuSection },
  computed: {},
  data() {
    return {
      store
      // windowWidth: null
    }
  }
}
</script>
<style scoped>
img {
  height: unset;
  width: 100%;
  border-radius: 0.5rem;
}
</style>
