<template>
  <div class="unpaid">
    <h3 class="title">
      Неоплаченый заказ <span>{{ orderTotal }} <small>рублей</small></span>
    </h3>
    <p>{{ order }}</p>
    <div class="buttons">
      <button
        @click="$router.push({ name: 'order', query: { id: orderData.id } })"
        class="button default">
        о заказе
      </button>
      <a v-if="orderData.payment.status !=='canceled'" :href="orderData.payLink" class="button primary"> Оплатить </a>
    </div>
  </div>
</template>

<script>
import { orderTotal, orderList } from '@/components/methods/listItems'
export default {
  name: 'UnpaidOrderItem',
  props: ['orderData'],
  computed: {
    order() {
      return orderList(this.orderData)
    },
    orderTotal() {
      return orderTotal(this.orderData)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.unpaid {
  background: var(--textBg);
  padding: 1rem;
  border-radius: var(--radius);
  font-size: 0.7rem;
  margin-left: -1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.title {
  display: flex;
  justify-content: space-between;
}
small {
  font-size: 0.5rem;
}
.button {
  appearance: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.4rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-family: 'Gill Sans', sans-serif;
  transition: all 200ms;
  text-decoration: none;
  box-sizing: border-box;
  border: none;
  outline: unset;
  margin: 0;
}
.primary {
  background: #cae2a1;
  color: #589633;
}
.default {
  background: #ededed;
  color: #717171;
}
.button:hover.primary {
  background: #589633;
  color: #cae2a1;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  gap: 0.5rem;
}
</style>
