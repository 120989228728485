<template>
  <div><slot /></div>
</template>

<script>
export default {
  name: "OrderContent",
};
</script>

<style scoped>
div {
  width: 100%;
  max-width: 25rem;
  margin: auto;
  box-sizing: border-box;
}
</style>
