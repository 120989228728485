<template>
  <div class="table">
    <div class="columns header">
      <h2>Кухня</h2>
    </div>
    <div class="columns">
      <div
        v-for="order in cooking"
        :key="order.number"
        class="order"
        :class="warn(order.whenCreated)">
        <div class="number">
          {{ order.number }} <span>{{ timer(order.whenCreated) }}</span>
        </div>
        <div class="list">
          <div class="line" v-for="item in order.items" :key="item.product.id">
            {{ item.product.name }} <span>x {{ item.amount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KitchenScreen',
  components: {},
  data() {
    return {
      data: []
    }
  },
  computed: {
    cooking() {
      return this.data.filter(order => order.status === 'CookingStarted' && order.payments)
    },
    ready() {
      return this.data.filter(order => order.status === 'CookingCompleted')
    }
  },
  methods: {
    timer(input) {
      const now = new Date()
      const time = new Date(input.split(' ').join('T'))
      const spent = now - time

      const hours = new Date(spent).getHours() - 3
      const minutes = new Date(spent).getMinutes()
      const seconds = new Date(spent).getSeconds()
      const addZero = num => (num >= 10 ? num : '0' + num)
      // return now
      return addZero(hours) + ':' + addZero(minutes) + ':' + addZero(seconds)
    },
    warn(input) {
      const now = new Date()
      const time = new Date(input.split(' ').join('T'))
      const spent = now - time
      const min5 = 5 * 1000 * 60
      const min10 = 10 * 1000 * 60
      const min15 = 15 * 1000 * 60
      // const min30 = 30 * 1000 * 60
      return spent > min15
        ? 'bad'
        : spent < min15 && spent > min10
        ? 'warn'
        : spent < min10 && spent > min5
        ? 'good'
        : spent < min5 && 'new'
    },
    async fetchOrders() {
      const res = await fetch('https://api.veresk.club/pizza/orders-screen')
      this.data = await res.json()
      console.log('fetch')
    },
    async getOrdersStatuses() {
      setInterval(async () => {
        await this.fetchOrders()
      }, 1000 * 10)
    }
  },
  async beforeMount() {
    await this.getOrdersStatuses()
  },
  async mounted() {
    console.log('mounted Kitchen')
    await this.fetchOrders()
  }
}
</script>
<style scoped>
.table {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  padding: 3rem;
  box-sizing: border-box;
}
.columns {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1rem 0;
  gap: 1rem;
}
.header {
  background: #e8e8e8;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  height: min-content;
}
.header h2 {
  width: 100%;
  color: #4e4e4e;
  font-weight: 600;
  font-size: 2rem;
}
.col {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.columns:not(.header) .col:last-child {
  border-left: 2px solid #e8e8e8;
}
/* .columns:not(.header) .col {
} */
.number {
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  background: #00000010;
}
.number span {
  font-size: 0.7em;
  color: #000;
}
.number.ready {
  background: #589633;
  color: #cae2a1;
}
.number.cooking {
  background: #d6efff;
  color: #0b4d8a;
}
.order {
  background: #cdffbe;
  border-radius: 1rem;
  font-size: 1rem;
  color: #1d370e;
  overflow: hidden;
}
.order.good {
  background: #beebff;
  color: #0b4d8a;
}
.order.warn {
  background: #f6d78a;
  color: #3c2a0d;
}
.order.bad {
  background: #e78585;
  color: #381b1b;
}
.line {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 0.5rem;
}
.list {
  padding: 1.2rem;
}
.line:nth-child(even) {
  background: #00000010;
  border-radius: 0.4rem;
}
</style>
