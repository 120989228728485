<template>
  <OrderContent>
    <OrderContentTitle>Оформление Заказа</OrderContentTitle>
    <div v-if="loading" class="loading">
      <p>Приступаем к созданию заказа. <br>Ожидайте</p>
    </div>
    <div v-else>
      <div v-if="store.total">
        <div v-if="isWorking">
          <!-- <div v-if="true"> -->
          <div v-if="!currentOrder">
            <OrderItemsList />
            <CartTotal />
            <MessageCard class="space">
              <p>
                Оставьте пожалуйста Ваши данные для оформления заказа. Они
                пригодятся если что-то пойдет не так. Мы их не храним.
              </p>
              <p>
                Оплата происходит на сервисе
                <img
                  :src="require('@/assets/images/logos/yooKassa.svg')"
                  alt="Юкасса логотип"
                  class="logo" />
              </p>
            </MessageCard>
            <div class="userdata">
              <div class="field">
                <img
                  class="icon"
                  :src="require('@/assets/images/icons/dino.png')" />
                <input type="text" placeholder="Имя" v-model="customer.name" />
              </div>
              <div class="field">
                <img
                  class="icon"
                  :src="require('@/assets/images/icons/smartphone.png')" />
                <input
                  type="text"
                  placeholder="Телефон"
                  v-model="customer.phone"
                  @input="checkPhone()"
                  maxlength="15" />
              </div>
            </div>
            <div class="buttons">
              <CartButton
                @click="createOrder"
                :style="'primary'"
                :disabled="!formValid"
                >Оформить</CartButton
              >
              <CartButton @click="$router.push({ name: 'Home' })">
                Добавить
              </CartButton>
            </div>
          </div>
          <div v-else>
            <MessageCard class="space">
              <p>
                Ваш заказ успешно создан с номером
                <strong>{{ currentOrder.order.number }}</strong>
              </p>
              <p>После оплаты мы приступим к его приготовлению</p>
            </MessageCard>
            <br />
            <div class="buttons">
              <CartButton
                @click="payOrder"
                :style="'primary'"
                :disabled="!formValid"
                >Оплатить</CartButton
              >
            </div>
          </div>
        </div>
        <div v-else>
          <MessageCard class="space">
            <p>В данный момент кухня закрыта.</p>
            <p>
              Мы работаем с <strong>12:00</strong> до
              <strong>21:00</strong>
            </p>
            <CartButton
              :style="'primary'"
              @click="$router.push({ name: 'Home' })">
              Перейти в меню
            </CartButton>
          </MessageCard>
        </div>
      </div>

      <div v-else>
        <MessageCard>
          <p>В данный момент Вы не выбрали ни одной позиции меню.</p>
          <CartButton
            :style="'primary'"
            @click="$router.push({ name: 'Home' })">
            Перейти в меню
          </CartButton>
        </MessageCard>
      </div>
    </div>
  </OrderContent>
</template>

<script>
import { store, orders, ordersList } from '@/data/menu'

import CartTotal from '@/components/cart/CartTotal'
import OrderItemsList from '@/components/cart/OrderItemsList'
import CartButton from '@/components/cart/CartButton.vue'
import MessageCard from '@/components/cards/MessageCard.vue'
import OrderContent from '@/components/OrderContent.vue'
import OrderContentTitle from '../components/OrderContentTitle.vue'
export default {
  name: 'OrderView',
  components: {
    CartTotal,
    OrderItemsList,
    CartButton,
    MessageCard,
    OrderContent,
    OrderContentTitle
  },
  data() {
    return {
      store,
      orders,
      ordersList,
      payLink: false,
      customer: { name: '', phone: '+7 ' },
      currentOrder: null,
      loading: false
    }
  },
  computed: {
    order() {
      return store.menu
        .filter(section => section.items.some(item => item.cart))
        .map(section => ({
          section: { title: section.title, icon: section.icon },
          items: section.items
            .filter(item => item.cart)
            .map(item => {
              return {
                id: item.id,
                name: item.name,
                cart: item.cart,
                price: item.price
              }
            })
        }))
    },
    isWorking() {
      const date = new Date()
      const h = date.getHours()
      const m = date.getMinutes()
      console.log(h, m)
      // console.log(h >= 12 && h < 21)
      return h >= 12 && h < 21 // +3
    },
    formValid: function () {
      return !!(this.customer.name && this.customer.phone.length > 14)
    },
    phoneValid() {
      return '+' + this.phone.replace(/\D/g, '')
    }
  },
  methods: {
    prepareOrder(items) {
      return items
        .map(cart => cart.items)
        .flat()
        .map(item => {
          return {
            id: item.id,
            name: item.name,
            cart: item.cart,
            price: item.price
          }
        })
    },
    checkPhone() {
      if (this.customer.phone[0] !== '+') {
        this.customer.phone = '+' + this.customer.phone
      } else if (
        this.customer.phone[0] === '+' &&
        this.customer.phone[1] !== '7'
      ) {
        let arr = this.customer.phone.split('')
        arr.splice(1, 0, '7')
        this.customer.phone = arr.join('')
      }
      if (this.customer.phone.length > 2 && this.customer.phone[2] !== ' ') {
        let arr = this.customer.phone.split('')
        arr.splice(2, 0, ' ')
        this.customer.phone = arr.join('')
      }
      if (this.customer.phone.length > 6 && this.customer.phone[6] !== ' ') {
        let arr = this.customer.phone.split('')
        arr.splice(6, 0, ' ')
        this.customer.phone = arr.join('')
      }
      if (this.customer.phone.length > 10 && this.customer.phone[10] !== ' ') {
        let arr = this.customer.phone.split('')
        arr.splice(10, 0, ' ')
        this.customer.phone = arr.join('')
      }
    },
    saveOrderData(data) {
      orders.push({
        id: data.metadata.orderID,
        payment: { paid: data.paid, status: data.status, paymentID: data.id },
        iikoOrder: { status: false, id: null },
        created_at: data.created_at,
        payLink: data.confirmation.confirmation_url,
        items: this.order,
        total: store.total,
        customer: this.customer
      })
      window.localStorage.setItem('orders', JSON.stringify(orders))
    },
    async createOrder() {
      this.loading = true
      let items = this.prepareOrder(this.order)
      let customer = this.customer
      let total = store.total

      const result = await fetch('https://api.veresk.club/pizza/order/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ items, total, customer })
      })
      const data = await result.json()
      this.currentOrder = data
      this.currentOrder.items = this.order
      // this.currentOrder.iikoOrder.status = data.status
      // this.currentOrder.iikoOrder.creationStatus = data.creationStatus
      console.log('afterCreate', this.currentOrder)
      ordersList.push({
        pay: this.currentOrder.pay,
        order: this.currentOrder.id,
        number: this.currentOrder.order.number,
        items: this.currentOrder.items,
        total: store.total
      })
      window.localStorage.setItem('ordersList', JSON.stringify(ordersList))
      this.loading = false
      // return data
    },
    async payOrder() {
      const result = await fetch('https://api.veresk.club/pizza/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          pay: this.currentOrder.pay.id,
          order: this.currentOrder.id,
          total: store.total
        })
      })

      if (result) {
        //* Переходим по ссылке для оплаты от YKassa
        window.location.href = this.currentOrder.pay.link
      }
    }
  }
}
</script>
<style scoped>
.logo {
  height: 1rem;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0.18rem;
}
.space {
  margin-top: 1.5rem;
}
div {
  box-sizing: border-box;
}
.userdata {
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
input {
  appearance: none;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid #c4c4c4;
  border-radius: 0.4rem;
}
.icon {
  height: 2.25rem;
}

.field {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.buttons {
  display: flex;
  gap: 1rem;
}
.buttons * {
  width: 100%;
}
.loading {
  background: #ececec;
  padding: 1rem;
  border-radius: var(--radius);
  font-size: 0.8rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  
  text-align: center;
}
</style>
