<template>
  <h3><CategoryIcon :file="data.icon" class="icon"/> {{ data.title }}</h3>
</template>

<script>
import CategoryIcon from "../CategoryIcon.vue";
export default {
    name: "CartCategoryTitle",
    props: ["data"],
    components: { CategoryIcon }
}
</script>

<style scoped>
h3{
    display: flex;
    align-items: center;
    gap: .8rem;
    position: relative;
    margin-bottom: .75rem;
    color: #643162;
}
.icon{
    height: 1.8em;

}
</style>