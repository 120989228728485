<template>
  <OrderContent v-if="orders.length">
    <pre>{{ordersList}}</pre>
    <OrderListItem
      v-for="(order, i) in listWitchOutNumber"
      :data="order"
      :key="i" />
    <OrderListItem
      v-for="(order, i) in list"
      :data="order"
      :key="i" />
  </OrderContent>
  <OrderContent v-else>
    <p>Предыдущих заказов не обнаружено</p>
  </OrderContent>
</template>

<script>
import { orders, ordersList } from '@/data/menu'
import OrderListItem from '@/components/orders/OrderListItem.vue'
import OrderContent from '@/components/OrderContent.vue'
export default {
  name: 'OrdersList',
  components: { OrderListItem, OrderContent },
  data() {
    return {
      orders,
      ordersList,
      list: null,
      listWitchOutNumber: null,
    }
  },
  computed:{
   

  },
  mounted() {
    const awaitOrders = async () => {
      let waitedOrders = new Promise(resolve =>
        setTimeout(() => resolve(orders), 1000)
      )
      const data =  await waitedOrders
      this.list = data.filter(item => item?.iikoOrder?.order?.number).reverse()
      this.listWitchOutNumber = data.filter(item => !item?.iikoOrder?.order?.number)
      console.log(this.list)
    }
    awaitOrders()
  }
}
</script>

<style></style>
