<template>
  <header>
    <div class="badge">5 мин от зеленогорска</div>
    <div class="layout">
      <transition name="fade" appear>
        <div class="logos">
          <router-link to="/">
            <img
              class="lavka"
              src="@/assets/images/logo.svg"
              alt="Логотип Пиццерии Лавка на Семагинском озере - ресторан Вереск" />
          </router-link>
          <div class="titles">
            <h2>Лавка пицца на озере красавица</h2>
            <h3>Готовим 15мин</h3>
          </div>
          <!-- <img
            class="veresk"
            src="@/assets/images/logo-veresk.svg"
            alt="Логотип ресторана Вереск" /> -->
          <NavigationMenu />
        </div>
      </transition>
      <div class="mobile titles" v-if="$route.name !== 'menuItemCard'">
        <h2>Лавка пицца на озере красавица</h2>
        <h3>Готовим 15мин</h3>
      </div>
    </div>
  </header>
</template>
<script>
import NavigationMenu from './NavigationMenu.vue'
export default {
  components: { NavigationMenu },
  name: 'Header'
}
</script>
<style scoped>
.badge {
  text-transform: uppercase;
  text-align: center;
  padding: 0.6rem;
  background: #dc73be;
  color: white;
}
.titles {
  text-align: right;
  width: 50%;
  margin-top: -0.8rem;
}
.mobile.titles {
  display: none;
}
.logos {
  display: flex;
  justify-content: space-between;
}
header {
  background: var(--bgLightBlue);
  position: relative;
  margin-bottom: 2em;
}
header:after {
  content: '';
  height: 75px;
  width: 100%;
  background: url('../assets/images/sosulki.svg') repeat-x bottom center;
  position: absolute;
  bottom: -68px;
  left: 0;
}
header .layout {
  padding: var(--layoutPadding) var(--layoutPadding) 50px var(--layoutPadding);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
@media (max-width: 1020px) {
  .veresk {
    display: none;
  }
  .lavka {
    width: 15rem;
    margin: 0 2em 0;
  }
  header .layout {
    padding-bottom: 1em;
  }
  header {
    margin-bottom: 4em;
  }
}
@media (max-width: 480px) {
  .titles {
    display: none;
  }
  .mobile.titles {
    display: unset;
    width: 100%;
    text-align: left;
    font-size: 0.7rem;
  }
  .lavka {
    margin: 0;
    width: unset;
  }
}
</style>
