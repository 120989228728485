<template>
  <div class="menuSection">
    <div class="header">
      <CategoryIcon :file="icon" />
      <h2>{{ title }}</h2>
    </div>
    <div class="items" v-if="items">
      <MenuItem
        v-for="(item, i) in items"
        :key="i"
        :item="item"
        :title="item.title" />
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
import CategoryIcon from './CategoryIcon.vue'
export default {
  name: 'menuSection',
  props: ['title', 'icon', 'items'],
  components: { MenuItem, CategoryIcon },
  data() {
    return {
      even: null
    }
  },
  methods: {
  },
  mounted() {
    this.even = this.items.filter(item => !item.title).length % 2 !== 0
  }
}
</script>

<style lang="css" scoped>
.menuSection {
  position: relative;
  background: var(--textBg);
  border-radius: var(--radius);
  padding: var(--textPadding);
  margin-top: 4em;
}
.menuSection .header {
  display: flex;
  align-items: flex-start;
  position: relative;
  top: -5em;
  margin-bottom: calc(-4.5em + var(--textPadding));
  padding-left: 1em;
}
.header h2 {
  margin-top: 0.6em;
  margin-left: 0.4em;
  font-size: 2.6em;
  font-weight: normal;
}
.menuSection .items {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 900px) {
  .menuSection .items {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 480px) {
  .header h2 {
    font-size: 2em;
    margin: unset;
    line-height: 1;
  }
  .menuSection .header {
    padding-left: 0;
    margin: unset;
    margin-bottom: 2rem;
    top: unset;
    align-items: center;
    gap: 1rem;
  }
  .menuSection {
    margin: 0 calc(var(--textPadding) * -1);
  }
  .menuSection .items {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.4em;
  }
}
</style>
