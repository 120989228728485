<template>
  <div class="btn"><slot /></div>
</template>

<script>
export default {
  name: 'ValueButton'
}
</script>

<style scoped>
.btn {
  background: #CCB1C5;
  border-radius: 0.2rem;
  box-sizing: border-box;
  min-width: 2rem;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
}
.cartView .btn{
  height: 1.2rem;
  min-width: 1.3rem;
}
</style>
