<template>
  <img :src="src" />
</template>

<script>
export default {
  name: 'CategoryIcon',
  props: ['file'],
  computed: {
    src() {
      try {
        return require(`@/assets/images/category/${this.file}.png`)
      } catch (e) {
        return require(`@/assets/images/category/pizza.png`)
      }
    }
    // srcset() {
    //   try {
    //     return (
    //       require(`@/assets/images/category/${this.file}.png`) +
    //       " 1x," +
    //       require(`@/assets/images/category/x2/${this.file}.png`) +
    //       " 2x," +
    //       require(`@/assets/images/category/x3/${this.file}.png}`) +
    //       " 3x"
    //     );
    //   } catch (error) {
    //     return (
    //       require(`@/assets/images/category/pizza.png`) +
    //       " 1x," +
    //       require(`@/assets/images/category/x2/pizza.png`) +
    //       " 2x," +
    //       require(`@/assets/images/category/x3/pizza.png`) +
    //       " 3x"
    //     );
    //   }
    // },
  }
}
</script>

<style>
@media (max-width: 480px) {
  img {
    height: 3rem;
  }
}
</style>
