<template>
  <transition name="open" appear mode="in-out">
    <img :src="require('@/assets/images/icons/remove.png')" />
  </transition>
</template>

<script>
export default {
  name: "RemoveIcon",
};
</script>

<style scoped>
img {
  align-self: center;
  margin-right: .3rem;
}
.open-enter-active,
.open-leave-active {
  transition: all 0.3s ease;
}

.open-enter-from,
.open-leave-to {
  opacity: 0;
}
</style>
