<template>
  <transition name="slide" appear>
    <div class="item">
      <div v-if="from === 'review'" class="ballon">
        <slot />
        <a :href="reviewLink"> отзыв</a>
      </div>
      <div v-else :class="from !== 'orderNumber' ? 'ballon' : 'order'">
        <slot />
      </div>
      <img :src="require(`@/assets/images/icons/kitchen.png`)" alt="Иконка" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ChatMessage',
  props: ['from'],
  data() {
    return {
      reviewLink:
        'https://yandex.ru/maps/org/lavka/105343253354/reviews/?ll=30.252640%2C60.024416&mode=search&sll=30.315635%2C59.938951&sspn=0.821228%2C0.284244&tab=reviews&text=вереск%20лавка&z=10'
    }
  }
}
</script>

<style scoped>
.item {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  --radius: 0.8rem;
}
.ballon {
  background: #f5e9f2;
  color: #643162;
  font-size: 1rem;
  border-radius: var(--radius);
  padding: 1rem 1.2rem;
  max-width: 70%;
  display: inline-block;
  box-sizing: border-box;
}
.order {
  width: 100%;
  font-size: 5rem;
  padding: 1.5rem 1rem;
  background: #d6efff;
  color: #0b4d8a;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-content: center;
}
img {
  height: 2.5rem;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
