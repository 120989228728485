<template>
  <h1><slot /></h1>
</template>

<script>
export default {
  name: "OrderContentTitle",
};
</script>

<style scoped>
h1 {
  font-size: 1.6rem;
  color: var(--titleRed);
  padding: 0.5rem 0.8rem;
  background: var(--bgVeresk);
  margin-bottom: 2rem;
  border-radius: 0.4rem;
}
</style>
