const orderTotal = orderData => {
  console.log('It works', orderData)
  return orderData.items
    .reduce((prev, curr) => [...prev, ...curr.items], [])
    .reduce((a, b) => a + b.price * b.cart, 0)
}

const orderList = orderData => {
  return orderData.items
    .reduce((prev, curr) => [...prev, ...curr.items], [])
    .map(item => item.name)
    .join(', ')
}
export { orderTotal, orderList }
