<template>
  <h2 class="title" v-if="title">{{ title }}</h2>
  <div v-if="allowed" class="menuItem" :class="{ empty: allowed }">
    <MenuItemPicture :alt="item.name" />
    <div class="itemInfo">
      <div class="info">
        <h3>
          {{ item.name }}
          <small v-if="item.weight && item.weight !== '0г.'">{{
            item.weight
          }}</small>
        </h3>
        <div class="price_cont">
          <!-- <div class="price last">{{ item.price * 2 }}</div> -->
          <div class="price">{{ item.price }}</div>
        </div>
      </div>
      <AddToCart
        v-if="cartShow || windowWidth < 480 || item.cart"
        :value="item.cart"
        :item="item"
        :mobile="windowWidth < 480" />
    </div>
  </div>
  <div
    v-else
    class="menuItem"
    :class="{ empty: allowed }"
    @mouseenter="cartShow = true"
    @mouseleave="cartShow = false">
    <router-link :to="item.id">
      <MenuItemPicture :alt="item.name" />
    </router-link>
    <div class="itemInfo">
      <div class="info">
        <h3>
          {{ item.name }} <small>{{ item.weight }}</small>
        </h3>
        <!-- <div class="price">{{ item.price }}</div> -->
        <div class="price_cont">
          <!-- <div class="price last">{{ item.price * 2 }}</div> -->
          <div class="price">{{ item.price }}</div>
        </div>
      </div>
      <AddToCart
        v-if="cartShow || windowWidth < 480 || item.cart"
        :value="item.cart"
        :item="item"
        :mobile="windowWidth < 480"
        @click.stop="Increase" />
    </div>
  </div>
</template>
<script>
import AddToCart from './AddToCart.vue'
import MenuItemPicture from './MenuItemPicture.vue'

export default {
  components: { MenuItemPicture, AddToCart },
  name: 'MenuItem',
  props: ['item', 'title'],
  computed: {
    itemCart() {
      return this.item
    },
    allowed() {
      return this.item.balance <= 4
    }
  },
  data() {
    return {
      cartShow: false,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    Increase() {
      this.itemCart.cart < this.item.balance - 4 && this.itemCart.cart++
    }
  }
}
</script>
<style lang="css">
.menuItem {
  background: white;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* justify-content: start; */
}
.menuItem.empty {
  opacity: 0.3;
}
.menuItem h3 {
  font-size: 1.2em;
  text-transform: none;
}
.menuItem h3 small {
  opacity: 0.3;
  font-size: 1rem;
}
.menuItem .price {
  font-size: 1.8em;
  font-weight: normal;
}
.menuItem .price.last {
  opacity: 0.4;
  text-decoration: line-through;
  font-weight: normal;
}
.menuItem .price_cont {
  display: flex;
  gap: 0.3rem;
}
.menuItem .price::after {
  content: '\20BD';
  font-size: 0.7em;

  font-family: 'Roboto Condensed', sans-serif;
}
.menuItem .info {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  width: 100%;
  box-sizing: border-box;
}
.itemInfo {
  width: 100%;
}
.menuItem.noImage .info {
  align-items: center;
}
h2.title {
  margin-top: 0.2em;
  margin-left: 0.4em;
  font-size: 2em;
  font-weight: normal;
  grid-column: span 2;
}
@media (max-width: 900px) {
  h2.title {
    grid-column: unset;
  }
}
@media (max-width: 480px) {
  .menuItem .price {
    font-size: 1.35em;
  }
  .menuItem h3 {
    font-size: 1.08em;
  }
  .menuItem {
    flex-direction: column;
  }
  .menuItem .info {
    flex-direction: column;
    font-size: 0.75rem;
    color: #643162;
  }
  .itemInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .menuItem h3 small {
    opacity: 0.3;
    font-size: 0.7rem;
  }
}
</style>
