<template>
  <transition name="fade" appear>
    <img :src="require('@/assets/images/icons/cart.png')" />
  </transition>
</template>
<script>
export default {
  name: 'CartIcon'
}
</script>
<style scoped>
img {
  ---max: 2.8rem;
  height: 100%;
  width: 100%;
  max-height: var(---max);
  max-width: var(---max);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(0.1rem);
}
</style>
