<template>
  <div class="unpaid">
    <h3 class="title">
      Все Готово можно забирать<span
        >{{ orderTotal }} <small>рублей</small></span
      >
    </h3>
    <p>{{ order }}</p>
    <div class="buttons">
      <div class="number">
        {{ orderData.iikoOrder?.order?.number }}
      </div>
      <button
        @click="$router.push({ name: 'order', query: { id: orderData.id } })"
        class="button primary">
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import { orderTotal, orderList } from '@/components/methods/listItems'
export default {
  name: 'CompleteOrderItem',
  props: ['orderData'],
  computed: {
    order() {
      return orderList(this.orderData)
    },
    orderTotal() {
      return orderTotal(this.orderData)
    }
  }
}
</script>

<style scoped>
.unpaid {
  background: var(--textBg);
  padding: 1rem;
  border-radius: var(--radius);
  font-size: 0.7rem;
  margin-left: -1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.title {
  display: flex;
  justify-content: space-between;
}
small {
  font-size: 0.5rem;
}
.button {
  appearance: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.4rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-family: 'Gill Sans', sans-serif;
  transition: all 200ms;
  text-decoration: none;
  box-sizing: border-box;
  border: none;
  outline: unset;
  margin: 0;
}
.primary {
  background: #cae2a1;
  color: #589633;
}
.default {
  background: #ededed;
  color: #717171;
}
.button:hover.primary {
  background: #589633;
  color: #cae2a1;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 0.5rem;
  margin-top: 1rem;
}
.number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #589633;
  color: #cae2a1;
  padding: 0.2rem 0.8rem;
  border-radius: 0.3rem;
  font-size: 1.8rem;
  box-shadow: inset 1px 1px 0 #0b4d8a15;
}
</style>
