<template>
  <div
    class="cart"
    :class="{ show: orderTotal > 0 && open, preview: orderTotal > 0 }">
    <div class="title" @click="open = !open">
      <h2 :class="{ open: orderTotal > 0 && !open }">
        <img :src="require('@/assets/images/icons/cart.png')" />
        <span>Заказ</span><CartTotal :numbers="true" />
      </h2>
    </div>
    <div class="container">
      <OrderItemsList :cart="true" />
      <CartTotal />
      <div class="buttons">
        <CartButton @click="clearCart">Очистить</CartButton>
        <CartButton @click="createOrder" :style="'primary'"
          >Оформить</CartButton
        >
      </div>
      <!-- <div class="buttons">
        <p class="msg">
          В данный момент мы не можем принять Ваш заказ!!! <br />Ведутся
          технические работы
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import CartButton from './cart/CartButton.vue'
import OrderItemsList from './cart/OrderItemsList.vue'
import CartTotal from './cart/CartTotal.vue'
import { store } from '@/data/menu'
export default {
  name: 'ShoppingCart',
  data() {
    return {
      store,
      open: false
    }
  },
  components: {
    CartButton,
    OrderItemsList,
    CartTotal
  },
  computed: {
    orderTotal() {
      return store.menu
        .map(section => section.items.filter(item => item.cart))
        .flat()
        .reduce((a, b) => a + b.price * b.cart, 0)
    }
  },
  methods: {
    clearCart() {
      store.menu.map(section => section.items.map(item => (item.cart = 0)))
      this.open = false
    },
    createOrder() {
      this.$router.push({
        name: 'newOrder'
      })
    }
  }
}
</script>

<style scoped>
.cart {
  --step: 0.5rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  transform: translateX(calc(100% + 2rem));
  background: white;
  border-radius: 0.85rem;
  box-shadow: 0.1rem 0.1rem 0.6rem #00000040;
  min-width: 25rem;
  box-sizing: border-box;
  transition: all 200ms;
}
.cart .msg {
  background: rgb(255, 160, 131);
  padding: 1rem;
  border-radius: 0.4rem;
  font-weight: 400;
  margin-bottom: 2rem;
}
.cart.show {
  transform: translateX(0);
}
.cart .container,
.title h2 {
  padding: 1rem;
}
.buttons {
  margin-top: 0.85rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.7rem;
}
.title {
  background: var(--bgVeresk);
  border-radius: 0.85rem 0.85rem 0 0;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}
.title img {
  height: 2.9rem;
  margin: -1rem 0.8rem -1rem -0.3rem;
}
.title h2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  --slide: 0;
  background: var(--bgVeresk);
  right: var(--slide);
  font-size: 1.6rem;
  color: #643162;
  line-height: 1;
  position: relative;
  border-radius: 0.85rem;
  transition: 200ms all;
}
.title h2.open {
  --slide: 4.5rem;
}
@media (max-width: 480px) {
  .title img {
    margin: -0.5rem 0;
  }
  .cart {
    top: unset;
    right: unset;
    min-width: unset;
    width: 100%;
    height: 100vh;
    left: 0;
    bottom: 0;
    transform: translateX(0);
    transform: translateY(100%);
    transform-origin: 0;
  }
  .cart.preview {
    transform: translateY(calc(100% - 3.5rem));
  }
  .cart.show {
    transform: translateY(0);
  }
  .title h2.open {
    --slide: 0;
  }
}
</style>
