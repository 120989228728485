<template>
  <div class="test" v-if="item.balance && item.balance >= 5">
    <div v-if="mobile">
      <div v-if="value === 0" class="mobileBlock">
        <CartIcon class="icon" /> Добавить
      </div>
      <div v-else class="mobileBlock value">
        <CartValue :value="value" :item="item" />
      </div>
    </div>
    <div v-else class="addToCart">
      <CartValue :value="value" :item="item" class="margin" />
      <CartIcon class="icon" />
    </div>
  </div>
</template>
<script>
import CartIcon from './cart/CartIcon.vue'
import CartValue from './cart/CartValue.vue'
export default {
  name: 'AddToCart',
  props: ['value', 'item', 'mobile'],
  components: { CartIcon, CartValue }
}
</script>
<style scoped>
.addToCart {
  position: absolute;
  bottom: 0.5em;
  right: 1em;
  display: flex;
  gap: 1.4rem;
  align-items: flex-end;
}
.margin {
  margin-bottom: 0.3rem;
  transform: scale(1.3);
}
.icon {
  --h: 2.2em;
  height: var(--h);
  width: var(--h);
}

@media (max-width: 480px) {
  .addToCart {
    position: unset;
    justify-content: center;
    align-self: flex-end;
  }
  .icon {
    --h: 2.16em;
  }
  .mobileBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    padding: 0.25rem 0.5rem;
    background: #d8a1cc75;
    margin: 0.35rem;
    border-radius: 0.3rem;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #643162;
    line-height: 1;
    box-sizing: border-box;
  }
  .mobileBlock.value {
    justify-content: center;
    padding: 0.25rem;
  }
}
</style>
