<template>
  <div class="block">
    <div class="slide" @click="showNextSlide">
      <img
        ref="slide"
        :src="require(`../assets/photos/${slides[current]}@3.jpg`)"
        alt="" />
    </div>
    <div class="thumbs" ref="container">
      <div
        class="thumb"
        :class="{ current: current === i }"
        v-for="(slide, i) in slides"
        :key="i"
        @click="current = i"
        :ref="`slide_${i}`">
        <img :src="require(`../assets/photos/${slide}.jpg`)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  methods: {
    showNextSlide() {
      this.current =
        this.current < this.slides.length - 1 ? this.current + 1 : 0
      let xPosition = this.$refs['slide_' + this.current].offsetLeft
      this.$refs.container.scrollTo(xPosition, 0)
    }
  },
  data() {
    return {
      current: 0,
      slides: [
        'house-2',
        'house-3',
        'house-5',
        'house-6',
        'house-7',
        'house-8',
        'house-9',
        'house-10',
        'house-11',
        'house-12',
        'house-13',
        'house-14',
        'house-15',
        'house-16',
      ]
    }
  }
}
</script>

<style scoped>
img{
    height: unset;
}
.block {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}

.slide {
  display: flex;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: var(--radius);
  overflow: hidden;
  max-height: 70vh;
}

.thumbs {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border-radius: var(--radius) var(--radius) 0 0;
  padding-bottom: 1em;
  scroll-behavior: smooth;
}

.thumb {
  overflow: hidden;
  height: 152px;
  width: 213px;
  border-radius: var(--radius);
  margin-right: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  border: 5px solid transparent;
  transition: all 200ms;
  opacity: .4;
}

.thumb:last-child {
  margin-right: 0;
}

.thumb.current {
  opacity: 1;
}

.thumbs::-webkit-scrollbar {
  height: 5px;
  margin-top: 10px;
}

.thumbs::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 5px;
  background-color: #fff;
}

.thumbs::-webkit-scrollbar-thumb {
  background-color: rgba(216, 161, 204, 0.74);
  border-radius: 4px;
  transition: all 200ms;
}

.thumbs::-webkit-scrollbar-thumb:hover {
  background-color: #d8a1cc;
}

.thumb img {
  min-height: inherit;
  max-height: 110%;
  /*object-fit: cover;*/
  display: flex;
}

.slide img {
  width: 100%;
}

@media (max-width: 420px) {
  .thumb {
    height: 80px;
    width: 110px;
    margin-right: 15px;
  }

  .block {
    grid-gap: 15px;
  }
}
</style>
