<template>
  <OrderContent>
    <OrderContentTitle>Ваш заказ</OrderContentTitle>
    <div class="order" v-if="currentOrder">
      {{ currentOrder.number }}
    </div>
    <div v-if="payInfo">
      <div class="ballon" v-if="iiko.order.status === 'Cancelled'">
        Заказ отменен
      </div>
      <div class="ballon" :class="{ success: payInfo.paid }" v-else>
        <span>Заказ {{ payInfo.paid ? 'оплачен' : 'не оплачен' }}</span>
        <CartButton v-if="!payInfo.paid && iiko.order.status !== 'Cancelled'" :style="'primary'" @click="pay"
          >Оплатить</CartButton
        >
      </div>
      <div
        class="ballon"
        :class="{ success: iiko.order.status === 'CookingCompleted' }"
        v-if="iiko && payInfo.paid">
        <span v-if="iiko.order.status === 'CookingCompleted'">
          Заказ приготовлен, можно забирать
        </span>
        <span v-else-if="iiko.order.status === 'Closed'">
          Статус заказа: Выполнен
        </span>
        <span v-else-if="iiko.order.status === 'Cancelled'">
          Статус заказа: <strong>Отменен.</strong> <br>Если заказ отменен не по Вашей просьбе,
          свяжитесь с нами по телефону
          <a href="tel:+79119222714">+7 911 922 2714</a>
        </span>
        <span v-else>Статус заказа: Готовим</span>
      </div>
      <div class="ballon" v-if="iiko && !payInfo.paid && iiko.order.status !== 'Cancelled'">
        Начнем готовить после оплаты
      </div>
    </div>
    <!-- <pre>{{ currentOrder }}</pre>
    <pre>=={{ payInfo }}</pre> -->
    <!-- <pre v-if="iiko">=={{ iiko }}</pre> -->

    <div class="items">
      <div v-for="(item, i) in items" :key="i" class="line">
        <div>{{ item.name }}</div>
        <div>x {{ item.cart }}шт.</div>
      </div>
    </div>

    <!-- <pre>currentOrder = {{ currentOrder }}</pre> -->
    <!-- <pre> ===>ордера{{ orders }}</pre> -->
    <!-- <pre> ===>currentOrder.payment.paid {{ currentOrder.payment.paid }}</pre> -->
  </OrderContent>
</template>

<script>
// import { orderList } from '@/components/methods/listItems'
import { orders, ordersList } from '@/data/menu'
import CartButton from '@/components/cart/CartButton.vue'
import OrderContent from '../components/OrderContent.vue'
import OrderContentTitle from '../components/OrderContentTitle.vue'
// import ChatMessage from '../components/messaging/ChatMessage.vue'
// import ChatFeed from '../components/messaging/ChatFeed.vue'
export default {
  name: 'OrderInfo',
  components: {
    CartButton,
    OrderContent,
    OrderContentTitle
    // ChatMessage,
    // ChatFeed
  },
  data() {
    return {
      orders,
      ordersList,
      payLink: false,
      payInfo: false,
      iiko: false,
      messages: [],
      loading: false
    }
  },
  computed: {
    currentOrder() {
      let out = ordersList.find(
        item => item.number === Number(this.$route.query.id)
      )
      out &&
        this.checkOrderPayment(out.pay.id) &&
        this.chekOrderStatus(out.order)
      return out
    },
    items() {
      return (
        this.currentOrder &&
        this.currentOrder.items.map(section => section.items).flat()
      )
    }
  },
  methods: {
    pay() {
      window.location.href = this.currentOrder.pay.link
    },
    async checkOrderPayment(id) {
      const result = await fetch('https://api.veresk.club/pizza/pay/status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
      })
      const data = await result.json()
      const { paid, payment_method, status } = data
      this.payInfo = { paid, payment_method, status }
      // return data
    },
    prepareOrder(items) {
      return items
        .map(cart => cart.items)
        .flat()
        .map(item => {
          return {
            id: item.id,
            cart: item.cart,
            price: item.price
          }
        })
    },
    updateLocalOrder() {
      window.localStorage.setItem('orders', JSON.stringify(orders))
    },
    async chekOrderStatus(id) {
      console.log('Проверка заказа начата')
      const result = await fetch('https://api.veresk.club/pizza/order/' + id)
      const data = await result.json()
      // console.log('sss',data)
      if (data.status === 400) {
        return
      } else {
        this.iiko = data
      }
    }
  }
}
</script>
<style scoped>
.order {
  width: 100%;
  font-size: 5rem;
  padding: 1.5rem 1rem;
  background: #d6efff;
  color: #0b4d8a;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
}
.line {
  display: flex;
  justify-content: space-between;
}
.items,
.ballon {
  background: #ececec;
  padding: 1rem;
  border-radius: var(--radius);
  font-size: 0.8rem;
  margin-top: 1rem;
}
.ballon.success {
  background: rgb(221, 241, 180);
}
.ballon {
  display: flex;
  justify-content: space-between;
}
</style>
