<template>
  <OrderContent>
    <OrderContentTitle>Ваш заказ</OrderContentTitle>

    <ChatFeed v-if="messages.length">
      <ChatMessage
        v-for="(message, i) in messages"
        :key="i"
        :from="message.from">
        {{ message.msg }}
      </ChatMessage>
      <ChatMessage v-if="loading">секундочку, ожидаем ответа</ChatMessage>
      <div>
        <CartButton
          v-if="showMenuButton"
          :style="'primary'"
          @click="$router.push({ name: 'Home' })">
          Перейти в меню
        </CartButton>
        <CartButton
          v-if="
            !currentOrder.payment.paid &&
            currentOrder.payment.status !== 'canceled'
          "
          :style="'primary'"
          @click="pay">
          Оплатить
        </CartButton>
      </div>
    </ChatFeed>

    <pre>currentOrder = {{ currentOrder }}</pre>
    <!-- <pre> ===>ордера{{ orders }}</pre> -->
    <!-- <pre> ===>currentOrder.payment.paid {{ currentOrder.payment.paid }}</pre> -->
  </OrderContent>
</template>

<script>
import { orders } from '@/data/menu'
import CartButton from '@/components/cart/CartButton.vue'
import OrderContent from '../components/OrderContent.vue'
import OrderContentTitle from '../components/OrderContentTitle.vue'
import ChatMessage from '../components/messaging/ChatMessage.vue'
import ChatFeed from '../components/messaging/ChatFeed.vue'
export default {
  name: 'CreateOrderView',
  components: {
    CartButton,
    OrderContent,
    OrderContentTitle,
    ChatMessage,
    ChatFeed
  },
  data() {
    return {
      orders,
      currentOrder: null,
      payLink: false,
      payStatus: null,
      messages: [
       
      ],
      loading: true,
      CookingStarted: false,
      CookingCompleted: false,
      Closed: false
    }
  },
  computed: {
    showMenuButton() {
      return (
        this.currentOrder?.iikoOrder?.order?.status === 'Closed' ||
        !this.currentOrder
      )
    }
  },
  methods: {
    pay() {
      window.location.href = this.currentOrder.payLink
    },
    async checkOrderPayment(paymentID) {
      console.log('start Check')
      this.loading = true
      const result = await fetch('https://api.veresk.club/pizza/pay/status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: paymentID })
      })
      const data = await result.json()
      this.currentOrder.payment.cardInfo = data.payment_method
      this.currentOrder.payment.status = data.status
      this.currentOrder.payment.paid = data.status === 'succeeded'
      this.loading = false

      this.currentOrder.payment.paid
        ? this.messages.push({
            msg: 'Мы получили оплату за ваш заказ'
          })
        : this.messages.push({
            msg: 'К сожалению оплата не прошла'
          })
    },
    prepareOrder(items) {
      return items
        .map(cart => cart.items)
        .flat()
        .map(item => {
          return {
            id: item.id,
            cart: item.cart,
            price: item.price
          }
        })
    },
    updateLocalOrder() {
      window.localStorage.setItem('orders', JSON.stringify(orders))
    },
    async createOrder() {
      this.loading = true
      let items = this.prepareOrder(this.currentOrder.items)
      let customer = this.currentOrder.customer
      let total = this.currentOrder.total
  
      const result = await fetch('https://api.veresk.club/pizza/order/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ items, total, customer })
      })
      const data = await result.json()
      console.log(data)
      this.currentOrder.iikoOrder.id = data.id
      this.currentOrder.iikoOrder.status = data.status
      this.currentOrder.iikoOrder.creationStatus = data.creationStatus
      console.log('afterCreate', this.currentOrder)
      return
      // return data
    },
    async chekOrderStatus() {
      const id = this.currentOrder.iikoOrder.id
      // console.log('order id', id)
      console.log('Проверка заказа начата')
      const result = await fetch('https://api.veresk.club/pizza/order/' + id)
      const data = await result.json()
      // console.log('sss',data)
      if (data.status === 400) {
        return
      } else {
        this.currentOrder.iikoOrder = data
      }
      console.log(
        'creationStatus from server',
        data.creationStatus,
        data.order.status
      )
      if (data.creationStatus === 'Success') {
        if (
          data.order.status === 'CookingStarted' ||
          data.order.status === 'ReadyForCooking'
        ) {
          this.loading = false
          !this.CookingStarted &&
            this.messages.push(
              {
                msg: 'Мы получили Ваш заказ и приступаем к его приготовлению.'
              },
              {
                msg: this.currentOrder?.iikoOrder?.order?.number,
                from: 'orderNumber'
              },
              {
                msg: 'Мы сообщим вам о готовности, ожидайте'
              }
            )
          this.CookingStarted = true
          console.log('CookingStarted')
          console.log(data)
          return setTimeout(async () => await this.chekOrderStatus(), 10 * 1000)
        } else if (data.order.status === 'CookingCompleted') {
          console.log('Заказ готов')
          this.currentOrder.iikoOrder.order = data.order
          !this.CookingStarted
            ? this.messages.push(
                {
                  msg: 'Ваш заказ приготовлен, можно забирать'
                },
                {
                  msg: this.currentOrder?.iikoOrder?.order?.number,
                  from: 'orderNumber'
                }
              )
            : this.messages.push({
                msg: 'Ваш заказ приготовлен, можно забирать'
              })
          this.loading = false
          this.updateLocalOrder()
          return
        } else if (data.order.status === 'Closed') {
          this.Closed = true
          console.log('Заказ Закрыт', this.currentOrder)
          this.currentOrder.iikoOrder.order = data.order
          this.messages = [
            {
              msg: 'Ваш заказ закрыт'
            },
            {
              msg: 'Мы будем рады если вы оставите',
              from: 'review'
            }
          ]
          this.updateLocalOrder()
          return
        }
      } else {
        console.log('else')
        return setTimeout(async () => await this.chekOrderStatus(), 10 * 1000)
      }
      this.updateLocalOrder()
      return
    }
  },
  mounted() {
    const awaitOrders = async () => {
      this.loading = true
      let waitedOrders = new Promise(resolve =>
        setTimeout(() => resolve(orders), 3000)
      )
      let desire = await waitedOrders
      let idFromRoute = Promise.resolve(this.$route.query.id)
      let id = await idFromRoute

      // console.log(desire, id)

      this.currentOrder = desire.find(order => order.id === id)

      !this.currentOrder &&
        (console.log('Такого заказа нет', id),
        this.messages.push({
          msg: `Мы не нашли заказа с номером ${id}`,
          from: 'sad'
        }))
      console.log('currentOrder', this.currentOrder)
      await this.checkOrderPayment(this.currentOrder.payment.paymentID)

      if (this.currentOrder.payment.paid) {
        console.log('Заказ оплачен')
        this.loading = false
        this.currentOrder?.iikoOrder?.id
          ? await this.chekOrderStatus()
          : (await this.createOrder(),
            await this.chekOrderStatus(),
            this.updateLocalOrder())
      }
    }
    awaitOrders()
  },
  unmounted() {
    this.updateLocalOrder()
  }
}
</script>
