<template>
  <div><slot /></div>
</template>

<script>
export default {
  name: "MessageCard",
};
</script>

<style scoped>
div {
  background: lightyellow;
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;
}
p,div,li {
  color: #4b2800;
  font-weight: normal;
  font-size: 1rem;
}
</style>
