<template>
  <div ref="map" id="map">
    <div class="info">
      <a href="https://veresk.club"><img src="@/assets/images/logo-veresk.svg" alt="Логотип ресторана &quote;Вереск&quote;"></a>
      <div class="status">
        <h3>+7 (812) 407-33-01</h3>
        <a href="https://veresk.club">veresk.club</a>
      </div>
    </div>
  </div>
</template>
<script>
import ymaps from "ymaps";

export default {
  name: "YaMap",
  data() {
    return {
      zoom: 18,
    };
  },
  mounted() {
    const poiImg = require("@/assets/images/poi.svg");
    let centerCoords =
      window.innerWidth > 420 ? [60.248357, 29.782212] : [60.2482, 29.7838];
    ymaps.load().then((maps) => {
      let myMap = new maps.Map(
        "map",
        {
          center: centerCoords,
          zoom: this.zoom,
          controls: ["geolocationControl", "trafficControl"],
        },
        {
          searchControlProvider: "yandex#search",
        }
      );

      let poi = new maps.Placemark(
        [60.248503, 29.783535],
        {
          hintContent: "Вкусная еда и приятная атмосфера",
          balloonContentHeader: "Демократичная пиццерия и бар «Лавка»",
          balloonContentBody:
            "Здесь готовим квадратную римскую пиццу и легкие салаты, миксуем коктейли и подаем на террасе с потрясающим видом на озеро Красавица.",
          balloonContentFooter: 'OOO "Линтуа"',
        },
        {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: "default#image",
          // Своё изображение иконки метки.
          iconImageHref: poiImg,
          // Размеры метки.
          iconImageSize: [135, 61],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-14, -61],
        }
      );

      // Добавляем многоугольник на карту.
      //   myMap.geoObjects.add(myGeoObject).add(poi);
      myMap.geoObjects.add(poi);
      console.log(myMap.getZoom());
    });
  },
};
</script>
<style lang="css" scoped>
#map {
  height: 550px;
  max-height: 60vh;
  /* clip-path: polygon(0% 0%,100% 0%,100% 100%,100% 0%) */
  clip-path: inset(0 0 0 0 round var(--radius));
  position: relative;
}
.info {
  width: 35%;
  padding: 2.1em 0.4em;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(15px);
  position: absolute;
  top: 50%;
  left: var(--layoutPadding);
  transform: translateY(-50%);
  z-index: 1;
  border-radius: calc(var(--radius) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4f4f4f;
}
.info img {
  width: 80%;
  opacity: 0.5;
}
a {
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: #4f4f4f;
  font-size: 1.8em;
}
.status a {
  display: block;
  font-weight: 100;
  line-height: 1.1;
  font-size: 1.9em;
}
.status {
  margin-top: 1.8em;
}
h3 {
  font-size: 2em;
  line-height: 1;
  color: #4f4f4f;
}
@media (max-width: 480px) {
  #map {
    height: 80vh;
    width: 100%;
    max-height: unset;
  }
  .info {
    --padding: 1em;
    width: calc(100% - var(--padding) * 3);
    top: unset;
    left: 50%;
    bottom: var(--padding);
    transform: translateY(0);
    transform: translateX(-50%);
  }
  .info img {
    width: 65%;
    opacity: 0.5;
  }
  .status {
    margin-top: 1em;
  }
  h3{
    font-size: 1.5em;
  }
  .status a{
    font-size: 1.5em;
  }
}
</style>