<template>
  <div
    class="item"
    @mouseenter="showRemove = true"
    @mouseleave="showRemove = false"
  >
    <div class="item-title">
      <RemoveIcon v-if="showRemove" class="remove" @click="toDel.cart = 0" />
      <span class="item-title">{{ item.name }}</span>
    </div>

    <CartValue :value="item.cart" :item="item"  :cartView="true"/>
    <div class="price">{{ item.price * item.cart }}<small>₽</small></div>
  </div>
</template>

<script>
import CartValue from "./CartValue.vue";
import RemoveIcon from "./RemoveIcon.vue";
export default {
  name: "ShopingCartItem",
  props: ["item"],
  components: { CartValue, RemoveIcon },
  computed: {
    toDel() {
      return this.item;
    },
  },
  data() {
    return {
      showRemove: false,
    };
  },
};
</script>

<style scoped>
.item {
  display: grid;
  grid-template-columns: auto 4rem 3rem;
  grid-gap: 0.7rem;
  color: #636363;
  padding: 0 0.3rem 0 0.5rem;
  border-radius: 0.4rem;
  margin: 0.1rem 0;
  cursor: pointer;
}
.item-title {
  translate: all 200ms;
  display: flex;
}

small {
  font-size: calc(1rem * 0.75);
  opacity: 0.8;
}
.price {
  text-align: right;
}
.remove {
  height: 0.88rem;
}
</style>
