<template>
  <div class="items-container" :class="{ inCart: cart }">
    <div v-for="(category, i) in orderItems" :key="i" class="menu-section">
      <CartCategoryTitle :data="category.section" />
      <ShopingCartItem
        v-for="item in category.items"
        :key="item.id"
        :item="item"
        class="line" />
    </div>
  </div>
</template>

<script>
import { store } from '@/data/menu'
import CartCategoryTitle from '@/components/cart/CartCategoryTitle'
import ShopingCartItem from '@/components/cart/ShopingCartItem'
export default {
  name: 'OrderItemsList',
  props: ['cart'],
  components: { CartCategoryTitle, ShopingCartItem },
  data() {
    return {
      store
    }
  },
  computed: {
    orderItems() {
      return store.menu
        .filter(section => section.items.some(item => item.cart))
        .map(section => ({
          section: { title: section.title, icon: section.icon },
          items: section.items.filter(item => item.cart)
        }))
    }
  }
}
</script>

<style scoped>
.menu-section:not(:last-child) {
  padding-bottom: 1rem;
}
.line:nth-child(odd) {
  background: #fcddec70;
}
.items-container {
  overflow: auto;
  height: unset;
  padding-bottom: 1rem;
}
.items-container.inCart {
  height: 60vh;
}
</style>
