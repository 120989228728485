<template>
  <UnpaidOrderItem v-if="!data?.payment?.paid" :orderData="data" />
  <CookingOrderItem
    v-if="
      data?.iikoOrder?.order?.status === 'CookingStarted' ||
      data?.iikoOrder?.order?.status === 'ReadyForCooking'
    "
    :orderData="data" />
  <CompleteOrderItem
    v-if="data?.iikoOrder?.order?.status === 'CookingCompleted'"
    :orderData="data" />
  <ClosedOrderItem
    v-if="data?.iikoOrder?.order?.status === 'Closed'"
    :orderData="data" />
  <div>
    <!-- {{data}} -->
  </div>
</template>

<script>
import UnpaidOrderItem from './UnpaidOrderItem.vue'
import CookingOrderItem from './CookingOrderItem.vue'
import CompleteOrderItem from './CompleteOrderItem.vue'
import ClosedOrderItem from './ClosedOrderItem.vue'
export default {
  name: 'OrderListItem',
  props: ['data'],
  components: {
    UnpaidOrderItem,
    CookingOrderItem,
    CompleteOrderItem,
    ClosedOrderItem
  },
  methods: {
    pay() {
      window.location.href = this.data.payLink
    }
  }
}
</script>

<style scoped>
.item {
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
}
</style>
