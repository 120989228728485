import { createRouter, createWebHistory } from 'vue-router'
// import Test from '@/views/Test'
import About from '@/views/About'
import Menu from '@/views/Menu'
import Order from '@/views/Order'
import CreateOrder from '@/views/CreateOrder'
import OrdersList from '@/views/OrdersList'
import OrdersScreen from '@/views/OrdersScreen'
import KitchenScreen from '@/views/KitchenScreen'
import OrderInfo from '@/views/OrderInfo'
import MenuItemCard from '@/views/MenuItemCard'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Menu
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/created',
    name: 'order',
    component: CreateOrder
  },
  {
    path: '/order-info',
    name: 'orderInfo',
    component: OrderInfo
  },
  {
    path: '/order',
    name: 'newOrder',
    component: Order
  },
  {
    path: '/orders',
    name: 'OrdersList',
    component: OrdersList
  },
  {
    path: '/orders-screen',
    name: 'OrdersScreen',
    component: OrdersScreen
  },
  {
    path: '/kitchen-screen',
    name: 'KitchenScreen',
    component: KitchenScreen
  },
  {
    path: '/:item',
    name: 'menuItemCard',
    component: MenuItemCard,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
