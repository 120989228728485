<template>
  <span v-if="numbers">{{ orderTotal }}<small>₽</small></span>
  <div v-else class="total">
    <div class="info">
      <img class="bill" :src="require('@/assets/images/icons/bill.png')" />
      <div class="sign">К оплате</div>
    </div>
    <div class="sum">{{ orderTotal }}<small>₽</small></div>
  </div>
</template>

<script>
import { store } from '@/data/menu'
export default {
  name: 'CartTotal',
  props: ['numbers'],
  data() {
    return {
      store
    }
  },
  computed: {
    orderTotal() {
      return store.menu
        .map(section => section.items.filter(item => item.cart))
        .flat()
        .reduce((a, b) => a + b.price * b.cart, 0)
    }
  },
  updated() {
    store.total = this.orderTotal
  }
}
</script>

<style scoped>
.total {
  --border: 1px dotted #643162;
  position: relative;
  border-top: var(--border);
  border-bottom: var(--border);
  padding: 1rem 0.5rem;
  font-size: 1.6em;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  line-height: 1;
  width: 100%;
  color: #643162;
  box-sizing: border-box;
}
.total .sign {
  font-size: 0.8em;
}
.sum {
  font-size: 1em;
}
.buttons {
  margin-top: 0.85rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.7rem;
}
.bill {
  height: 2.5rem;
}
.total .info {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
small {
  font-size: calc(1rem * 0.79);
  opacity: 0.8;
}
</style>
