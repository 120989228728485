<template>
  <div class="feed"><slot/></div>
</template>

<script>
export default {
    name:"ChatFeed"

}
</script>

<style scoped>
.feed{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

</style>