<template>
  <button
    :class="{
      primary: style === 'primary',
      default: !style,
      disabled: disabled
    }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'CartButton',
  props: ['style', 'disabled']
}
</script>

<style scoped>
button {
  appearance: none;
  cursor: pointer;

  text-transform: uppercase;
  font-size: 13px;
  padding: 0.7rem 1.4rem;
  border-radius: 0.25rem;
  border: none;
  font-family: 'Gill Sans', sans-serif;
  transition: all 200ms;
}
.primary {
  background: #cae2a1;
  color: #589633;
}
.default {
  background: #ededed;
  color: #717171;
}
button:hover.primary {
  background: #589633;
  color: #cae2a1;
}
button:hover.default {
  background: #717171;
  color: #ededed;
}
button.disabled {
  pointer-events: none;
  opacity: .4;
}
</style>
