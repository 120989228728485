<template>
  <div class="itemCard">
    <MenuItemCardPicture :alt="itemData.name" />
    <div class="description">
      <h2 v-if="sectionTitle">{{ itemData.name }}</h2>
      <!-- <pre>{{ itemData }}</pre> -->

      <p>{{ itemData.description }}</p>
      <p class="price">
        цена: <strong>{{ itemData.price }}</strong
        ><small>руб.</small>
      </p>
      <router-link to="/" class="back">&larr; меню</router-link>
    </div>
  </div>
</template>

<script>
import { store } from '@/data/menu'
import MenuItemCardPicture from '@/components/MenuItemCardPicture.vue'

export default {
  name: 'menuItemCard',
  components: { MenuItemCardPicture },
  props: ['item'],

  data() {
    return {
      store
    }
  },
  computed: {
    sectionTitle() {
      return this.store.menu.find(section =>
        section.items.some(item => item.id === this.item)
      )
    },
    itemData() {
      return this.store.menu
        .map(section => section.items.find(item => item.id === this.item))
        .filter(Boolean)[0]
    }
  },
  methods: {}
}
</script>

<style lang="css" scoped>
.menuSection {
  position: relative;
  background: var(--textBg);
  border-radius: var(--radius);
  padding: var(--textPadding);
  margin-top: 4em;
}
.menuSection .header {
  display: flex;
  align-items: flex-start;
  position: relative;
  top: -5em;
  margin-bottom: calc(-4.5em + var(--textPadding));
  padding-left: 1em;
}
.header h2 {
  margin-top: 0.6em;
  margin-left: 0.4em;
  font-size: 2.6em;
  font-weight: normal;
}
.menuSection .items {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
}
strong {
  font-weight: 400;
  font-size: 1.4em;
}
.itemCard {
  display: flex;
  gap: 3rem;
  box-sizing: border-box;
}
.description {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.back {
  padding: 1rem;
  background: var(--bgVeresk);
  color: white;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
}
.price {
  text-align: left;
  
}
@media (max-width: 900px) {
  .menuSection .items {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 480px) {
  .price {
  text-align: right;
}
  .back {
    width: 100%;
    position: unset;
    right: unset;
    bottom: unset;
  }
  .itemCard {
    flex-direction: column;
  }
  .header h2 {
    font-size: 2em;
    margin: unset;
    line-height: 1;
  }
  .menuSection .header {
    padding-left: 0;
    margin: unset;
    margin-bottom: 2rem;
    top: unset;
    align-items: center;
    gap: 1rem;
  }
  .menuSection {
    margin: 0 calc(var(--textPadding) * -1);
  }
  .menuSection .items {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.4em;
  }
}
</style>
