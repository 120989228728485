<template>
  <div class="empty">
    <img v-if="src" class="picture" :src="src"  :alt="alt" />
  </div>
</template>
<script>
import imagesDict from '@/data/imagesDict'
export default {
  name: 'MenuItemCardPicture',
  props: ['file', 'alt'],
  computed: {
    img() {
      return imagesDict[this.alt.trim()]
    },
    src() {
      try {
        return require(`@/assets/images/menu/${this.img.replace('.jpg','@3.jpg')}`)
      } catch (error) {
        console.log('Нет картинки ' + this.alt)
        return require(`@/assets/images/menu/no-photo@3.png`)
      }
    },
    srcset() {
      try {
        return (
          require(`@/assets/images/menu/${this.img.replace('.jpg','@3.jpg')}`) +
          ' w400 ' +
          require(`@/assets/images/menu/${this.img.replace('.jpg','@2.jpg')}`) +
          ' w580 ' +
          require(`@/assets/images/menu/${this.img.replace('.jpg','@3.jpg')}`) +
          ' w800 '
        )
      } catch (error) {
        console.log('Нет картинки ' + this.alt)
        return (
            require(`@/assets/images/menu/no-photo@3.png`) +
            ' w400 ' +
            require(`@/assets/images/menu/no-photo@2.png`) +
            ' w580 ' +
            require(`@/assets/images/menu/no-photo@3.png`) +
            ' w800 '
          )
      }
    }
  }
}
</script>
<style scoped>
img.picture {
  display: flex;
}
.empty {
  background: #f2f2f2;
  height: 20rem;
  width: 30rem;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  object-fit: cover;
  width: 100%;
}
@media (max-width: 480px) {
  .empty {
    height: 12rem;
    width: 100%;
    border-radius: 10px;
  }
  img {
    object-fit: cover;
    height: 100%;
  }
}
</style>
