<template>
  <div class="table">
    <div class="columns header">
      <div class="col">
        <h2>Готовим</h2>
      </div>
      <div class="col">
        <h2>Можно забирать</h2>
      </div>
    </div>
    <div class="columns">
      <div class="col">
        <div
          v-for="order in cooking"
          :key="order.number"
          class="number cooking">
          {{ order.number }}
        </div>
      </div>
      <div class="col">
        <div
          v-for="order in ready"
          :key="order.number"
          class="number ready"
          @click.once="closeOrder(order.id)">
          {{ order.number }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrdersScreen',
  components: {},
  data() {
    return {
      data: []
    }
  },
  computed: {
    cooking() {
      return this.data.filter(
        order => order.status === 'CookingStarted' && order.payments
      )
    },
    ready() {
      return this.data.filter(order => order.status === 'CookingCompleted')
    }
  },
  methods: {
    async fetchOrders() {
      const res = await fetch('https://api.veresk.club/pizza/orders-screen')
      this.data = await res.json()
      console.log('fetch')
    },
    async getOrdersStatuses() {
      setInterval(async () => await this.fetchOrders(), 1000 * 10)
    },
    async closeOrder(id) {
      const result = await fetch('https://api.veresk.club/pizza/order/close', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
      })
      const data = await result.json()
      console.log(data)
      await this.fetchOrders()
    }
  },
  async beforeMount() {
    await this.getOrdersStatuses()
  },
  async mounted() {
    await this.fetchOrders()
  }
}
</script>
<style scoped>
.table {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  padding: 3rem;
  box-sizing: border-box;
}
.columns {
  display: flex;
  box-sizing: border-box;
  height: 85%;
  justify-self: stretch;
}
.header {
  background: #e8e8e8;
  padding: 2rem 0;
  border-radius: 1rem;
  height: min-content;
}
.header h2 {
  text-align: center;
  width: 100%;
  color: #4e4e4e;
  font-weight: 600;
  font-size: 2rem;
}
.col {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.columns:not(.header) .col:last-child {
  border-left: 2px solid #e8e8e8;
}
/* .columns:not(.header) .col {
} */
.number {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 2rem;
  margin: 1rem;
  border-radius: 0.3rem;
  box-shadow: inset 0.4rem 0.4rem 0 #0b4d8a15;
  max-width: 30%;
  box-sizing: border-box;
}
.number.ready {
  background: #589633;
  color: #cae2a1;
}
.number.cooking {
  background: #d6efff;
  color: #0b4d8a;
}
</style>
