<template>
  <ValueButton>
    <img :src="require('@/assets/images/icons/minus.svg')" />
  </ValueButton>
</template>

<script>
import ValueButton from './ValueButton.vue'
export default {
  components: { ValueButton },
  name: 'Decrease'
}
</script>

<style scoped>
img {
  height: .125rem;
}
</style>
