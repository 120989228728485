<template>
  <ValueButton>
    <img :src="require('@/assets/images/icons/plus.svg')" />
  </ValueButton>
</template>

<script>
import ValueButton from './ValueButton.vue'
export default {
  name: 'Increase',
  components: { ValueButton }
}
</script>

<style scoped>
img {
  height: 50%;
}
</style>
