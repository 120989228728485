<template>
  <div class="block" :class="{ cartView: cartView }">
    <Decrease @click.stop="Decrease" />
    <div class="value">
      {{ value }}
    </div>
    <Increase @click.stop="Increase" />
  </div>
</template>

<script>
import Decrease from './Decrease.vue'
import Increase from './Increase.vue'
export default {
  name: 'CartValue',
  props: ['value', 'item', 'cartView'],
  components: { Decrease, Increase },
  computed: {
    itemCart() {
      return this.item
    }
  },
  methods: {
    Decrease() {
      this.value > 0 && this.itemCart.cart--
    },
    Increase() {
      this.itemCart.cart++
    }
  }
}
</script>

<style scoped>
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}
.value {
  width: 1.5rem;
  font-size: 1rem;
  text-align: center;
}
.cartView .value {
  margin: 0 .2rem;
}
</style>
